import { makeApi } from '@zodios/core';
import { z } from 'zod';

export const statisticsApi = makeApi([
	{
		alias: 'exportCsv',
		method: 'get',
		path: '/statistics/export',
		parameters: [
			{
				name: 'dateFrom',
				type: 'Query',
				schema: z.string().datetime({ offset: true }),
			},
			{
				name: 'dateTo',
				type: 'Query',
				schema: z.string().datetime({ offset: true }),
			},
			{
				name: 'aids',
				type: 'Query',
				schema: z.array(z.number()).optional(),
			},
		],
		response: z.instanceof(Response),
		responseType: 'blob',
	},
]);
