import { Zodios } from '@zodios/core';
import { ZodiosHooks } from '@zodios/react';

import { axiosInstance } from '@shared/api/axios-instance';

import { BASE_URL } from '../constants';

import { statisticsApi } from './api';

const statisticsApiClient = new Zodios(BASE_URL, statisticsApi, { axiosInstance });

export const statisticsApiHooks = new ZodiosHooks('statistics', statisticsApiClient);
